<template>
  <div class="inviting-work">
    <div>
      <el-button type="primary" round>客户管理</el-button>
      <el-button type="primary" plain round>我的合作</el-button>
    </div>

    <div class="table-wrap">
      <el-table
        size="mini"
        ref="multipleTable"
        :data="[]"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        header-row-class-name="table-head-row"
      >
        <el-table-column label="项目名称"> </el-table-column>

        <el-table-column prop="name" label="姓名" width="120">
        </el-table-column>

        <el-table-column prop="name" label="联系电话" width="120">
        </el-table-column>

        <el-table-column prop="name" label="提交时间" width="120">
        </el-table-column>

        <el-table-column prop="name" label="操作" width="120">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.inviting-work {
  .table-wrap {
    margin-top: 18px;
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }
}
</style>