<template>
  <div class="inviting-price">
    <div class="img">
      <img
        width="100%"
        :src="require('@/assets/images/ori/inviting-price.png')"
        alt=""
        srcset=""
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>