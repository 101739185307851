<template>
  <div class="inviting">
    <panel-title icon="el-icon-suitcase" title="创业招商" />

    <panel-content>
      <template #filter>
        <div class="filter-row">
          <span
            :class="{ active: showType === 'info' }"
            @click="showType = 'info'"
            >招商介绍</span
          >
          <span
            :class="{ active: showType === 'price' }"
            @click="showType = 'price'"
            >服务详情</span
          >
          <span
            :class="{ active: showType === 'create' }"
            @click="showType = 'create'"
            >创建项目</span
          >
          <span
            :class="{ active: showType === 'work' }"
            @click="showType = 'work'"
            >合作信息</span
          >
          <span
            :class="{ active: showType === 'bill' }"
            @click="showType = 'bill'"
            >招商订单</span
          >
        </div>
      </template>

      <template #content>
        <template v-if="showType === 'info'">
          <el-empty style="margin-top: 30px" description="招商信息"></el-empty>
        </template>

        <template v-if="showType === 'price'">
          <inviting-price />
        </template>

        <template v-if="showType === 'create'">
          <inviting-create />
        </template>

        <template v-if="showType === 'work'">
          <inviting-work />
        </template>

        <template v-if="showType === 'bill'">
          <inviting-bill />
        </template>
      </template>
    </panel-content>
  </div>
</template>

<script>
import InvitingBill from "./InvitingBill.vue";
import InvitingCreate from "./InvitingCreate.vue";
import InvitingPrice from "./InvitingPrice.vue";
import InvitingWork from "./InvitingWork.vue";
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: {
    PanelTitle,
    PanelContent,
    InvitingPrice,
    InvitingCreate,
    InvitingWork,
    InvitingBill,
  },
  data() {
    return {
      // 介绍info、价格详情price、创建项目create、合作信息work、招商订单bill
      // showType: "info",
    };
  },

  computed: {
    showType: {
      get() {
        return this.$route.query.type || "info";
      },

      set(v) {
        this.$router.push(this.$route.path + `?type=${v}`);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.inviting {
  .filter-row {
    span {
      cursor: pointer;
      display: inline-block;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #ccc;

      font-size: 14px;

      line-height: 17px;

      &:hover,
      &:active,
      &.active {
        color: red;
      }
    }

    span:last-child {
      border-right: none;
    }
  }
}
</style>