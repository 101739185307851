<template>
  <div class="inviting-bill">
    <div class="table-wrap">
      <el-table
        size="mini"
        ref="multipleTable"
        :data="[]"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        header-row-class-name="table-head-row"
      >
        <el-table-column label="购买服务"> </el-table-column>

        <el-table-column prop="name" label="订单号" width="120">
        </el-table-column>

        <el-table-column prop="name" label="截止时间" width="120">
        </el-table-column>

        <el-table-column prop="name" label="合作协议" width="120">
        </el-table-column>

        <el-table-column prop="name" label="操作" width="120">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.inviting-bill {
  .table-wrap {
    margin-top: 18px;
    ::v-deep .table-head-row th {
      background: #f4f5f9;
      color: #666;
    }
  }
}
</style>