<template>
  <div class="inviting-create">
    <div class="step-wrap">
      <el-steps :active="2" align-center>
        <el-step title="基本信息"></el-step>
        <el-step title="项目信息"></el-step>
        <el-step title="合作表单"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>

    <div class="form-wrap">
      <div class="head">基本信息</div>

      <el-form
        label-width="110px"
        label-position="right"
        size="medium
        "
      >
        <el-form-item label="企业名称：">
          <el-input style="width: 50%" placeholder="请输入企业名称" />
        </el-form-item>

        <el-form-item label="项目名称：">
          <el-input style="width: 50%" placeholder="请输入项目名称" />
        </el-form-item>

        <el-form-item label="所属行业：">
          <el-button type="primary">请选择类目</el-button>
        </el-form-item>

        <el-form-item label="品牌标签：">
          <el-input style="width: 50%" placeholder="按回车结束" />
        </el-form-item>

        <el-form-item label="招商地域：">
          <el-button type="primary">国际</el-button>
          <el-button type="primary" plain>全国</el-button>
          <el-button type="primary" plain>省市</el-button>
        </el-form-item>

        <el-form-item label="招商金额：">
          <div>
            <el-radio-group v-model="priceType">
              <el-radio label="0">区间：</el-radio>
              <el-radio label="1">以上：</el-radio>
              <el-radio label="2">以下：</el-radio>
            </el-radio-group>
          </div>
          <div style="margin-top: 8px">
            <div v-if="priceType === '0'">
              <el-input style="width: 150px" value="0" />
              <span style="margin: 0 10px">万元--</span>
              <el-input style="width: 150px" value="0" />
              <span style="margin: 0 10px">万元</span>
            </div>

            <div v-if="priceType === '1'">
              <el-input style="width: 150px" value="0" />
              <span style="margin: 0 10px">万元以上</span>
            </div>

            <div v-if="priceType === '2'">
              <el-input style="width: 150px" value="0" />
              <span style="margin: 0 10px">万元以下</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="企业所在地区：">
          <div>
            <el-select v-model="value" placeholder="请选择省">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-top: 8px">
            <el-input style="width: 50%" placeholder="详细地址..." />
          </div>
        </el-form-item>

        <el-form-item label="成立时间：">
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="适合人群：">
          <el-input placeholder="适合人群..." style="width: 50%" />
        </el-form-item>

        <el-form-item>
          <div>
            <el-button type="primary">下一步</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      priceType: "0",

      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",

      value1: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.inviting-create {
  .step-wrap {
    margin: 15px auto;
    width: 700px;
  }

  .form-wrap {
    .head {
      margin-bottom: 29px;
      padding-left: 25px;
      height: 40px;
      background: rgba(238, 238, 238, 1);
      opacity: 1;
      border-radius: 3px;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
  }
}
</style>